import { tns } from '../../../node_modules/tiny-slider/src/tiny-slider'
const $galleries = document.querySelectorAll('.act-galerie-slider ul');

(function ($) {
    $(document).ready(function () {
        $galleries.forEach(function ($gallery) {
            var $id = $gallery.getAttribute('id');
            var slider = tns({
                container: '#'+$id,
                items: 1,
                loop: true,
                center: false,
                mouseDrag: true,
                slideBy: 1,
                swipeAngle: false,
                "autoHeight": true,
                speed: 400,
                autoplay: false,
                controlsText: ['<svg class="icon icon-slide_previous"><use xlink:href="#icon-slide_previous"></use></svg>', '<svg class="icon icon-slide_next"><use xlink:href="#icon-slide_next"></use></svg>'],
                controlsPosition:"bottom",
                navPosition:"bottom",
                onInit(info) {
                    info.controlsContainer.setAttribute('tabindex', -1);
                    info.nextButton.setAttribute('tabindex', 0);
                    info.prevButton.setAttribute('tabindex', 0);
                    calculate_dots_position();
                }
            });
            slider.events.on('indexChanged', function (info, eventName) {
                remove_aria_hidden_slider();
            });
            slider.events.on('transitionStart', function (info, eventName) {
                calculate_dots_position();
            });
            function calculate_dots_position()
            {
                var $parent = $gallery.closest('.tns-outer');
                let height = $gallery.querySelector('.tns-slide-active img').offsetHeight;
                console.log(height);
                $parent.querySelector('.tns-nav').style.top = 'calc('+height+'px - 2rem)';
            }
        });
        remove_aria_hidden_slider();
    });

    function remove_aria_hidden_slider() {
        const $tns_nav = document.querySelectorAll(".act-galerie-slider .tns-controls button");

        if ($tns_nav) {

            $tns_nav.forEach(function ($nav) {
                var value = $nav.getAttribute('data-controls');
            
                if(document.documentElement.lang =='fr-CA'){
                    if(value == 'next'){
                        $nav.setAttribute('aria-label', 'Suivant');
                        $nav.setAttribute('title', 'Suivant');
                    }else{
                        $nav.setAttribute('aria-label', 'Précédent');
                        $nav.setAttribute('title', 'Précédent');
                    }
                
                }else{
                    $nav.setAttribute('aria-label', value);
                    $nav.setAttribute('title', value);
                }
            });
        }
        const $tns_hidden = document.querySelectorAll(".act-galerie-slider .tns-item");

        if ($tns_hidden) {

            $tns_hidden.forEach(function ($item) {
/* on ne peut pas faire ca, tiny se sert de ca pour mettre la classe active sur la bonne slide */
                // $item.removeAttribute('aria-hidden');
            });
        }
    }
})(jQuery);
